import React, { useState, useEffect } from "react";
import { Select, Input, message, Spin, Button, Table, Modal } from "antd";
import { http } from "../../../services/http";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import { getFiveDaysAgo, getToday } from "../../../utils/getDays";

const ShipProduct = ({ datas, onClose, visible }) => {
	const { t } = useTranslation();
	const employeeId = useSelector((state) => state.main.employeeId);
	const department = useSelector((state) => state.main.department);

	const [loading, setLoading] = useState(false);

	const today = getToday();
	const fiveDaysAgo = getFiveDaysAgo();

	const [date, setDate] = useState(today);

	const formattedTotal = new Intl.NumberFormat("fr-FR").format(datas.summa);

	const bonus = new Intl.NumberFormat("fr-FR").format(datas.u_BonusTotal);

	const [loadingData, setLoadingData] = useState({
		ddata: false,
		wdata: false,
		mdata: false,
	});

	const [data, setData] = useState({
		ddata: [],
		value: "",
		wdata: [],
	});

	const { ddata, value, wdata } = data;
	const initialSelectedWarehouses = datas.whsCode.reduce((acc, code, index) => {
		acc[index] = code;
		return acc;
	}, {});

	const [selectedWarehouses, setSelectedWarehouses] = useState(initialSelectedWarehouses);

	const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
	const [selectedComments, setSelectedComments] = useState("");
	const [comments, setComments] = useState("");

	const handleSelectClick = () => {
		setSelectedComments(data.comments);
		setIsCommentModalVisible(true);
	};

	const handleModalOk = () => {
		setIsCommentModalVisible(false);
	};

	const handleModalCancel = () => {
		setIsCommentModalVisible(false);
	};

	const handleWarehouseChange = (value, rowIndex) => {
		const selectedWarehouse = wdata.find((warehouse) => warehouse.code === value);
		const warehouseName = selectedWarehouse ? selectedWarehouse.code : "";
		setSelectedWarehouses((prevSelectedWarehouses) => ({
			...prevSelectedWarehouses,
			[rowIndex]: warehouseName,
		}));
	};
	useEffect(() => {
		const fetchAllData = async () => {
			setLoadingData({ ddata: true, wdata: true, mdata: true });
			await Promise.all([fetchDrivers(), fetchWarehouse()]);
			setLoadingData({ ddata: false, wdata: false, mdata: false });
		};
		fetchAllData();

		if (visible) {
			resetForm();
		}
	}, [visible]);

	useEffect(() => {
		const handleBeforePrint = () => {
			handleSaveAsPDF(true);
		};

		window.addEventListener("beforeprint", handleBeforePrint);

		return () => {
			window.removeEventListener("beforeprint", handleBeforePrint);
		};
	}, []);

	const fetchDrivers = async () => {
		try {
			const { data } = await http.get(`api/drivers`);

			const formattedData = data.map((item) => ({
				name: `${item.firstName} ${item.lastName}`,
				value: item.employeeID,
			}));

			setData((prevState) => ({
				...prevState,
				ddata: formattedData,
			}));
		} catch (err) {
			console.log(err);
		}
	};

	const fetchWarehouse = async () => {
		try {
			const { data } = await http.get(`api/warehouses`);
			const mappedItems = data.map((item) => ({
				code: item.warehouseCode,
				name: item.warehouseName,
			}));
			setData((prevState) => ({
				...prevState,
				wdata: mappedItems,
			}));
		} catch (err) {
			console.log(err);
		}
	};

	const handleDriverChange = (value) => {
		const selectedItem = ddata.find((item) => item.name === value);
		setData((prevState) => ({
			...prevState,
			value: selectedItem.value,
		}));
		console.log(selectedItem.value);
	};

	const postData = async () => {
		if (value) {
			setLoading(true);

			try {
				const documentLines = datas.maxsulot
					.map((item, index) => ({
						baseEntry: datas.docEntry,
						baseLine: datas.lineNum[index],
						itemCode: datas.itemCode[index],
						quantity: datas.quantity[index],
						warehouseCode: selectedWarehouses[index],
						unitPrice: datas.price[index],
						currency: datas.currency[index],
						u_BonusPerQty: datas.u_BonusPerQty[index],
						u_Category: datas.u_Category[index],
						u_ItemGroup: datas.u_ItemGroup[index],
					}))
					.filter((_, index) => datas.lineStatus[index] !== "C");

				await http.post("api/sotuvlar/ortib-yuborish", {
					cardCode: String(datas.cardCode),
					u_Driver: Number(value),
					salesPersonCode: datas.salesPersonCode !== "Нет" ? datas.salesPersonCode : -1,
					documentsOwner: employeeId,
					docDueDate: date,
					docDate: date,
					comments,
					U_Department:department,
					documentLines: documentLines,
				});
				message.success(t("sucCreated"));

				setTimeout(() => {
					resetForm();
					onClose();
				}, 800);
			} catch (error) {
				console.log("Error");
				message.error(t("errCreation") + error.message);
				console.log("Error:", error.message);
			} finally {
				setLoading(false);
			}
		} else {
			message.error(t("selectDriver"));
		}
	};

	const resetForm = () => {
		setData((prevState) => ({
			...prevState,
			value: "",
			managerId: "",
		}));
	};

	const handleSaveAsPDF = (isPrintEvent = false) => {
		const input = document.getElementById("ship-product-form");
		html2canvas(input, { scrollY: window.scrollY }).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF();
			const imgWidth = 180;
			const imgHeight = (canvas.height * imgWidth) / canvas.width;
			pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
			pdf.save("ship-product-form.pdf");

			if (isPrintEvent) {
				setTimeout(() => {
					window.print();
				}, 1000);
			}
		});
	};

	const columns = [
		{
			title: t("u_ItemGroup"),
			dataIndex: "u_ItemGroup",
			key: "u_ItemGroup",
			render: (text, record, index) => {
				return (
					<span className={`${datas.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{text}
					</span>
				);
			},
		},
		{
			title: t("u_Category"),
			dataIndex: "u_Category",
			key: "u_Category",
			render: (text, record, index) => {
				return (
					<span className={`${datas.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{text}
					</span>
				);
			},
		},

		{
			title: t("item"),
			dataIndex: "maxsulot",
			key: "maxsulot",
			width: 150,
			render: (text, record, index) => {
				return (
					<span className={`${datas.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{text}
					</span>
				);
			},
		},
		{
			title: t("quantity"),
			dataIndex: "quantity",
			key: "quantity",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return (
					<span className={`${datas.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{formattedText}
					</span>
				);
			},
		},
		{
			title: t("measureUnit"),
			dataIndex: "ugpName",
			key: "ugpName",
			render: (text, record, index) => {
				return (
					<span className={`${datas.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{text}
					</span>
				);
			},
		},

		{
			title: t("price"),
			dataIndex: "price",
			key: "price",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return (
					<span className={`${datas.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{formattedText} USD
					</span>
				);
			},
		},

		{
			title: t("totalMiqdor"),
			dataIndex: "lineTotal",
			key: "lineTotal",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return (
					<span className={`${datas.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{formattedText} USD
					</span>
				);
			},
		},
		{
			title: t("whs"),
			dataIndex: "whsName",
			key: "whsName",
			render: (text, record, index) => (
				<Select
					value={selectedWarehouses[index]}
					onChange={(value) => handleWarehouseChange(value, index)}
					className="w-[200px]"
					showSearch
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.children.toLowerCase().includes(input.toLowerCase())
					}
					disabled={datas.lineStatus[index] === "C"}
				>
					{wdata.map((warehouse) => (
						<Select.Option key={warehouse.code} value={warehouse.code}>
							{warehouse.name}
						</Select.Option>
					))}
				</Select>
			),
		},
	];
	if (String(department).toLowerCase() === "royal" || String(department).toLowerCase() === "artel") {
		columns.push(
			{
				title: t("u_BonusPerQty"),
				dataIndex: "u_BonusPerQty",
				key: "u_BonusPerQty",
				render: (text, record, index) => {
					const formattedText = new Intl.NumberFormat("fr-FR").format(text);
					return (
						<span className={datas.lineStatus[index] === "C" ? "text-slate-500" : ""}>
							{formattedText} USD
						</span>
					);
				},
			},
			{
				title: t("u_BonusSumm"),
				dataIndex: "u_BonusSumm",
				key: "u_BonusSumm",
				render: (text, record, index) => {
					const formattedText = new Intl.NumberFormat("fr-FR").format(text);
					return (
						<span className={datas.lineStatus[index] === "C" ? "text-slate-500" : ""}>
							{formattedText} USD
						</span>
					);
				},
			}
		);
	}

	const tableData = datas.maxsulot.map((item, index) => ({
		key: index,
		u_Category: datas.u_Category[index],
		u_ItemGroup: datas.u_ItemGroup[index],

		maxsulot: item,
		quantity: datas.quantity[index],
		price: datas.price[index],
		inventoryQuantity: datas.inventoryQuantity[index],
		lineTotal: datas.lineTotal[index],
		whsName: datas.whsName[index],
		ugpName: datas.ugpName[index],
		u_BonusPerQty: datas.u_BonusPerQty[index],
		u_BonusSumm: datas.u_BonusSumm[index],
	}));

	return (
		<div className="w-full">
			<div id="ship-product-form" className="w-full">
				<div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row">
					<div className="flex w-full flex-col gap-3 sm:w-auto">
						<p className="font-nunito">{t("mijoz")}</p>
						<Input
							type="text"
							value={datas.mijoz}
							className="flex h-12 w-full items-center justify-center sm:w-[250px]"
							style={{ color: "black" }}
							disabled
						/>
					</div>

					<div className="flex w-full flex-col gap-3 sm:w-auto">
						<p className="font-nunito">{t("date")}</p>

						<Input
							type="date"
							value={date}
							className="w-[150px]"
							onChange={(e) => {
								console.log(e.target.value);
								setDate(e.target.value);
							}}
							max={today}
							min={fiveDaysAgo}
						/>
					</div>
				</div>

				<Table
					columns={columns}
					dataSource={tableData}
					pagination={false}
					className="border-1 mt-12 border-black"
					scroll={{ x: "100%" }}
				/>

				<div className="mt-10 flex items-center justify-between">
					<div className="flex mt-10 flex-col gap-3">
						<p className="font-nunito">Izoh yozish</p>
						<Input
							type="text"
							value={comments}
							placeholder="Izoh yozish"
							onChange={(e) => {
								setComments(e.target.value);
							}}
							className="flex h-12  w-[200px] items-center justify-center sm:w-[250px]"
						/>
					</div>
					<div className="flex items-center justify-end gap-5">
						{/* <div className="flex flex-col gap-3">
							<p>{t("docTotalQuantity")}</p>
							<Input
								type="text"
								value={`${datas.docTotalQuantity} кг`}
								style={{ color: "black" }}
								className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
								disabled
							/>
						</div> */}
						{String(department).toLowerCase() === "royal" || String(department).toLowerCase() === "artel" ? (
							<div className="flex flex-col gap-3">
								<p>{t("BonusTotal")}</p>
								<Input
									type="text"
									value={`${bonus} USD`}
									style={{ color: "black" }}
									className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
									disabled
								/>
							</div>
						) : (
							""
						)}

						<div className="flex flex-col gap-3">
							<p>{t("totalPrice")}</p>
							<Input
								type="text"
								value={`${formattedTotal} USD`}
								style={{ color: "black" }}
								className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
								disabled
							/>
						</div>
					</div>
				</div>
				<div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row">
					<div className="flex w-full flex-col gap-3 sm:w-auto">
						<p>{t("driver")}</p>
						<Spin spinning={loadingData.ddata}>
							<Select
								value={value ? ddata.find((item) => item.value === value)?.name : undefined}
								placeholder={t("chooseDriver")}
								onChange={handleDriverChange}
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								className="flex h-12 w-full items-center justify-center sm:w-[250px]"
							>
								{ddata
									.filter((item) => item.name !== null)
									.map((item) => (
										<Select.Option key={item.value} value={item.name}>
											{item.name}
										</Select.Option>
									))}
							</Select>
						</Spin>
					</div>
				</div>
			</div>

			<div className="flex flex-col items-center justify-center">
				<div className="mt-20 flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
					<Button
						onClick={postData}
						className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[300px]"
						disabled={loading}
						loading={loading}
					>
						{t("ship")}
					</Button>

					<Button
						onClick={() => handleSaveAsPDF(false)}
						className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[300px]"
					>
						{t("savePDF")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ShipProduct;
