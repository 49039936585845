import { Modal, Input, Table, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const FinishedSalesModal = ({ visible, data, onClose }) => {
	const { t } = useTranslation();
	const { department } = useSelector((state) => state.main.department);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedComments, setSelectedComments] = useState("");

	const handleSelectClick = () => {
		setSelectedComments(data?.comments);
		setIsModalVisible(true);
	};

	const handleModalOk = () => {
		setIsModalVisible(false);
	};

	const handleModalCancel = () => {
		setIsModalVisible(false);
	};

	if (!data) return null;
	const formattedSumma = new Intl.NumberFormat("fr-FR").format(data.summa);
	const bonus = new Intl.NumberFormat("fr-FR").format(data.u_BonusTotal);

	const columns = [
		{
			title: t("u_ItemGroup"),
			dataIndex: "u_ItemGroup",
			key: "u_ItemGroup",
			render: (text, record, index) => {
				return (
					<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{text}
					</span>
				);
			},
		},
		{
			title: t("u_Category"),
			dataIndex: "u_Category",
			key: "u_Category",
			render: (text, record, index) => {
				return (
					<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{text}
					</span>
				);
			},
		},

		{
			title: t("item"),
			dataIndex: "maxsulot",
			key: "maxsulot",
			width: 150,
			render: (text, record, index) => {
				return (
					<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{text}
					</span>
				);
			},
		},
		{
			title: t("quantity"),
			dataIndex: "quantity",
			key: "quantity",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return (
					<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{formattedText}
					</span>
				);
			},
		},
		{
			title: t("measureUnit"),
			dataIndex: "ugpName",
			key: "ugpName",
			render: (text, record, index) => {
				return (
					<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{text}
					</span>
				);
			},
		},

		{
			title: t("price"),
			dataIndex: "price",
			key: "price",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return (
					<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{formattedText} USD
					</span>
				);
			},
		},

		{
			title: t("totalMiqdor"),
			dataIndex: "lineTotal",
			key: "lineTotal",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return (
					<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{formattedText} USD
					</span>
				);
			},
		},
		{
			title: t("whs"),
			dataIndex: "whsName",
			key: "whsName",
		},
	];

	if (String(department).toLowerCase() === "royal" || String(department).toLowerCase() === "artel") {
		columns.push(
			{
				title: t("u_BonusPerQty"),
				dataIndex: "u_BonusPerQty",
				key: "u_BonusPerQty",
				render: (text, record, index) => {
					const formattedText = new Intl.NumberFormat("fr-FR").format(text);
					return (
						<span className={data.lineStatus[index] === "C" ? "text-slate-500" : ""}>
							{formattedText} USD
						</span>
					);
				},
			},
			{
				title: t("u_BonusSumm"),
				dataIndex: "u_BonusSumm",
				key: "u_BonusSumm",
				render: (text, record, index) => {
					const formattedText = new Intl.NumberFormat("fr-FR").format(text);
					return (
						<span className={data.lineStatus[index] === "C" ? "text-slate-500" : ""}>
							{formattedText} USD
						</span>
					);
				},
			}
		);
	}

	// const tableData = data.maxsulot.map((item, index) => ({
	// 	key: index,
	// 	maxsulot: item,
	// 	quantity: data.quantity[index],
	// 	measureUnit: data.measureUnit[index],
	// 	price: data.price[index],
	// 	inventoryQuantity: data.inventoryQuantity[index],
	// 	lineTotal: data.lineTotal[index],
	// 	ugpName: data.ugpName[index],
	// 	whsName: data.whsName[index],
	// 	u_BonusPerQty: data.u_BonusPerQty[index],
	// 	u_BonusSumm: data.u_BonusSumm[index],
	// 	u_Category: data.u_Category[index],
	// 	u_ItemGroup: data.u_ItemGroup[index],
	// }));

	const tableData = data.maxsulot.map((item, index) => ({
		key: index,
		u_Category: data.u_Category[index],
		u_ItemGroup: data.u_ItemGroup[index],
		maxsulot: item,
		quantity: data.quantity[index],
		price: data.price[index],
		lineTotal: data.lineTotal[index],
		whsName: data.whsName[index],
		ugpName: data.ugpName[index],
		u_BonusPerQty: data.u_BonusPerQty[index],
		u_BonusSumm: data.u_BonusSumm[index],
	}));

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t("order")} № {data.docNum}
				</h1>
			}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="70%"
			style={{ height: "70%" }}
			bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-4 sm:px-14">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-nunito">{t("mijoz")}</p>
							<Input
								type="text"
								value={data.mijoz}
								className="flex h-12 w-[200px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-nunito">{t("date")}</p>
							<Input value={data.sana} style={{ color: "black" }} disabled />
						</div>
					</div>
					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: "100%" }}
					/>

					<div className="flex items-center justify-end gap-5">
						{/* <div className="flex flex-col gap-3">
							<p>{t("docTotalQuantity")}</p>
							<Input
								type="text"
								value={`${datas.docTotalQuantity} кг`}
								style={{ color: "black" }}
								className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
								disabled
							/>
						</div> */}
						{String(department).toLowerCase() === "royal" ||
						String(department).toLowerCase() === "artel" ? (
							<div className="flex flex-col gap-3">
								<p>{t("BonusTotal")}</p>
								<Input
									type="text"
									value={`${bonus} USD`}
									style={{ color: "black" }}
									className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
									disabled
								/>
							</div>
						) : (
							""
						)}

						<div className="flex flex-col gap-3">
							<p>{t("totalPrice")}</p>
							<Input
								type="text"
								value={`${formattedSumma} USD`}
								style={{ color: "black" }}
								className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
								disabled
							/>
						</div>
					</div>

					<div className="mt-10 flex items-center justify-between">
						<div className="flex flex-col gap-3">
							<p>{t("comments")}</p>
							<Select
								value={data.comments}
								className="flex h-12 w-full items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
								onClick={handleSelectClick}
							/>
							<Modal
								title={t("comments")}
								visible={isModalVisible}
								onOk={handleModalOk}
								onCancel={handleModalCancel}
							>
								<p>{selectedComments}</p>
							</Modal>
						</div>
						{/* <div className="flex items-center justify-end gap-5">
							<div className="flex flex-col gap-3">
								<p>{t("docTotalQuantity")}</p>
								<Input
									type="text"
									value={`${data.docTotalQuantity} кг`}
									style={{ color: "black" }}
									className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
									disabled
								/>
							</div>
							{/*
              <div className="flex flex-col gap-3">
                <p>{t("totalPrice")}</p>
                <Input
                  type="text"
                  value={`${formattedSumma} ${data.currency[0]}`}
                  style={{ color: "black" }}
                  className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                  disabled
                />
              </div> */}
						{/* </div> */}
					</div>
					<div className="mt-10 flex flex-col items-center justify-between gap-3 sm:flex-row">
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p>{t("driver")}</p>
							<Input
								type="text"
								value={data.driver}
								disabled
								style={{ color: "black" }}
								className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p>{t("responsiblePerson")}</p>
							<Input
								type="text"
								value={data.yaratdi}
								style={{ color: "black" }}
								disabled
								className="flex h-10 w-[200px] items-center justify-center border-[1px] border-[#D0D0D0]"
							/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default FinishedSalesModal;
